.creator-banner__avatar {
  outline: var(--image-outline-width) solid var(--image-outline-color);
  outline-offset: 4px;
  box-shadow: var(--image-shadow);
}

.creator-banner__summary {
  gap: 0.5rem;
  margin: 2rem 0 1rem 0;
}

.creator-banner__cover {
  background-image: var(--image-overlay-gradient), var(--header);
  background-size: cover;
  background-position: center;
}

.creator-banner__portrait {
  background-image: var(--image-overlay-gradient), var(--avatar);
  background-size: cover;
  background-position: center;
}

.theme-style--book .creator-banner-divider {
  width: 50%;
  border-bottom-width: 3px;
  border-color: var(--badge-border-color);
}

.font-theme-display {
  font-weight: var(--text-display-weight);
}
